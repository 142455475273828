<template>
  <div>
  <breadcrumb-nav>
    <template v-slot:firstMenu>任务管理</template>
    <template v-slot:secondMenu>审核详情</template>
  </breadcrumb-nav>
  <el-card shadow="never" class="standard-margin">
    <span class="font-title-medium">审核详情信息</span>
    <div class="form-container-border">
      <el-row>
        <el-col :span="6" class="form-border form-left-bg font-small">用户名</el-col>
        <el-col class="form-border font-small" :span="18">{{rewardItem.userInfo.showName}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="6" class="form-border form-left-bg font-small" style="height: 60px;">用户头像</el-col>
        <el-col class="form-border font-small" :span="18" style="height: 60px;">
          <img :src="rewardItem.userInfo.showAvatar" style="width: 30px;height: 30px;" alt="">
        </el-col>
      </el-row>
      <el-row>
        <el-col class="form-border form-left-bg font-small" :span="6">邀请码</el-col>
        <el-col class="form-border font-small" :span="18">{{rewardItem.userInfo.sCode}}</el-col>
      </el-row>
      <el-row>
        <el-col class="form-border form-left-bg font-small" :span="6">奖励金额</el-col>
        <el-col class="form-border font-small" :span="18">{{rewardItem.config.rmb}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="6" class="form-border form-left-bg font-small">提交时间</el-col>
        <el-col class="form-border font-small" :span="18">{{rewardItem.audit.createTime | formatDate}}</el-col>
      </el-row>
      <el-row>
        <el-col class="form-border form-left-bg font-small" :span="6">奖励金额</el-col>
        <el-col class="form-border font-small" :span="18">{{rewardItem.config.rmb}}</el-col>
      </el-row>
      <el-row>
        <el-col class="form-border form-left-bg font-small" :span="6">点赞数量</el-col>
        <el-col class="form-border font-small" :span="18">{{rewardItem.config.rate}}</el-col>
      </el-row>
      <el-row>
        <el-col class="form-border form-left-bg font-small" :span="6">当前状态</el-col>
        <el-col class="form-border font-small form-color" :span="18">{{rewardItem.audit.status | formatStatus}}</el-col>
      </el-row>
      <el-row v-show="rewardItem.audit.status===2">
        <el-col class="form-border form-left-bg font-small" :span="6" style="height:52px;line-height:32px">审核备注</el-col>
        <el-col class="form-border font-small" :span="18">
          <el-input  size="small" v-model="updateParam.auditNote" style="width:200px;margin-left: 10px"></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="form-container-border" v-show="rewardItem.audit.status===3 || rewardItem.audit.status===4">
      <el-row>
        <el-col class="form-border form-left-bg font-small" :span="6">审核时间</el-col>
        <el-col class="form-border font-small" :span="18">{{rewardItem.audit.auditTime | formatDate}}</el-col>
      </el-row>
      <el-row>
        <el-col class="form-border form-left-bg font-small" :span="6">审核备注</el-col>
        <el-col class="form-border font-small" :span="18">{{rewardItem.audit.auditNote || '无'}}</el-col>
      </el-row>
    </div>
    <div style="margin-top:15px;text-align: center" v-show="rewardItem.audit.status===2">
      <el-button type="primary" size="small" @click="handleUpdateStatus(3)">同意</el-button>
      <el-button type="danger" size="small" @click="handleUpdateStatus(4)">拒绝</el-button>
      <el-button type="success" size="small" @click="goBack">返回</el-button>
    </div>
    <div style="margin-top:15px;text-align: center" v-show="rewardItem.audit.status!==2">
      <el-button type="success" size="small" @click="goBack">返回</el-button>
    </div>
    <el-row v-show="rewardItem.audit.image">
      <el-col class="form-border form-left-bg font-small" :span="6">凭证图片
      </el-col>
    </el-row>
    <el-row>
      <el-col class="form-border font-small" :span="18">
        <img :src="rewardItem.audit.image" style="width: 300px" alt="">
      </el-col>
    </el-row>
  </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
// import * as QrCode from 'qrcode-decoder'
// import QrCode from '../../../../node_modules/qrcode-decoder/dist/index'
import { getRewardAuditDetailRequest, OperateRewardAuditRequest } from '../../../network/task'
const defaultUpdateParam = {
  status: 0,
  auditNote: '',
  auditId: 0
}
export default {
  name: 'InviteRecordDetail',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatStatus (value) {
      switch (value) {
        case 1:
          return '待上传'
        case 2:
          return '待审核'
        case 3:
          return '已发放'
        case 4:
          return '审核拒绝'
        case 5:
          return '过期'
        default:
          return '过期'
      }
    },
    formatDate (value) {
      if (value === 0) {
        return '无'
      }
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      const hour = (date.getHours() + '').padStart(2, '0')
      const minute = (date.getMinutes() + '').padStart(2, '0')
      const second = (date.getSeconds() + '').padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }
  },
  data () {
    return {
      auditId: 0,
      userId: 0,
      rewardItem: {
        config: {},
        audit: {},
        userInfo: {}
      },
      downloadUrlPrefix: '',
      updateParam: Object.assign({}, defaultUpdateParam)
    }
  },
  created () {
    this.downloadUrlPrefix = window.g.MEDIA_DOWNLOAD_URL
    this.auditId = this.$route.query.auditId
    this.userId = this.$route.query.userId
    this.updateParam.auditId = this.auditId
    this.getDetail()
  },
  methods: {
    getDetail () {
      getRewardAuditDetailRequest({ auditId: this.auditId, userId: this.userId }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取详情失败', 'error')
        }
        this.rewardItem = result.data
        if (this.rewardItem.audit.icon !== '') {
          if (this.rewardItem.audit.icon.includes('http')) {
            this.rewardItem.audit.image = this.rewardItem.icon
          } else {
            this.rewardItem.audit.image = this.downloadUrlPrefix + this.rewardItem.audit.icon
          }
        }
        if (this.rewardItem.audit.status === 2) {
          this.rewardItem.audit.auditNote = ''
        }
        if (this.rewardItem.userInfo.sUrl !== '') {
          this.rewardItem.userInfo.sCode = this.rewardItem.userInfo.sUrl.substring(this.rewardItem.userInfo.sUrl.lastIndexOf('/') + 1)
        }
        if (!this.rewardItem.userInfo.showAvatar.includes('http')) {
          this.rewardItem.userInfo.showAvatar = this.downloadUrlPrefix + this.rewardItem.userInfo.showAvatar
        }
        this.updateParam.auditNote = this.rewardItem.audit.auditNote
      })
    },
    handleUpdateStatus (status) {
      this.updateParam.status = status
      this.updateParam.auditId = parseInt(this.updateParam.auditId)
      console.log('updateParam:', this.updateParam)
      // eslint-disable-next-line no-unreachable
      this.$confirm('是否要进行此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        OperateRewardAuditRequest(this.updateParam).then(res => {
          console.log('handleUpdateStatus res:', res.data)
          this.alertMessage('操作成功', 'success')
          this.$router.back()
        })
      })
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style>
.detail-container {
}

.standard-margin {
  margin-top: 15px;
}
.form-border {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
}

.form-container-border {
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
  margin-top: 15px;
}

.form-left-bg {
  background: #F2F6FC;
}
.form-color {
  color: red;
}
</style>
